import AuthUserSlice from './auth-user/auth-user-slice';
import MessagesSlice from './messages/messages-slice';
import EditMessageSlice from './edit-message/edit-message-slice';
import RestaurantsSlice from './restarurants/restaurants-slice';


const reducers = {
    auth: AuthUserSlice,
    messages: MessagesSlice,
    editMessage: EditMessageSlice,
    restaurants: RestaurantsSlice,
}

export default reducers;