import React from "react";
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import Routes from "../../routes";
import Messages from "../messages/Messages";
import Logout from "../logout/Logout";
import { Flex } from "antd";
import Message from "../message/Message";


const routes = [
  { path: Routes.Dashboard.home, mainComponent: Messages },
  { path: Routes.Messages.Messages, mainComponent: Messages },
  { path: Routes.Messages.MessagePage, mainComponent: Message },
];

const NavigationSwitch = () => {
  return (
    <Switch >
      {
        routes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Flex>
                  {<route.mainComponent />}
                </Flex>}
            ></Route>

          )
        })
      }

      <Route path={Routes.Auth.logout} element={<Logout />} ></Route>
      <Route
        path="*"
        element={<Navigate to={Routes.Dashboard.home} replace />}
      />
    </Switch>
  )
}

export default NavigationSwitch;
