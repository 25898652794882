import React, { useCallback, useState, useEffect } from 'react'
import _ from 'lodash';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { Image, Toast } from 'antd-mobile';
import { ILoginForm } from '../../interfaces/components/forms/login';
import { loginStart, verifyCodeStart } from '../../store/features/auth-user/auth-user-slice';
import { useAppSelector, useAppDispatch } from '../../store/config';
import { NoMarginDivider, StyledMainTitle } from '../common/StyledComponents';
import PinInput from './PinInput';
import { isNumber } from '../../utils/utils';
import { LoginButton, LoginContainer } from './StyledComponents';
import Logo from '../../assets/images/images.jpeg';

const { Text } = Typography;
const { Item } = Form;

const LoginView = () => {

    const [value, setValue] = useState('');
    const [error, setError] = useState<string | null>(null)
    const dispatch = useAppDispatch();
    const authError = useAppSelector(state => state.auth.error);

    const onLoginSubmit = (data: ILoginForm) => {
        const { phone } = data;
        const formattedphone = formatIsraeliPhoneNumber(phone);
        if (formattedphone) {
            setError(null);
            dispatch(loginStart({ phone: formattedphone }));
        } else {
            setError('מספר טלפון לא תקין, אנא נסה שנית')
        }
    };

    useEffect(() => {
        setError(authError ? 'אירעה שגיאה, אנא נסה שנית' : null)
    }, [authError]);

    return (
        <Flex vertical justify='space-between' style={{ marginTop: '90px' }}>

            <StyledMainTitle level={4} style={{ marginBottom: '60px' }} >התחברות</StyledMainTitle>

            <Flex vertical gap={30} >
                <Text >טקסט תיאור</Text>
                <Form
                    name="loginPhoneForm"
                    layout="vertical"
                    requiredMark={false}
                    onFinish={onLoginSubmit} >
                    <Flex
                        vertical
                        justify='space-between'
                        style={{ minHeight: '100%', boxSizing: 'border-box' }} >
                        <Item
                            name="phone"
                            label={<Text strong>מספר טלפון נייד</Text>}
                            rules={[{ required: true, message: 'שדה חובה' }]} >
                            <Flex vertical>
                                <Input
                                    placeholder="מספר טלפון"
                                    bordered={false}
                                    style={{ width: '100%' }}
                                    value={value}
                                    onChange={(e) => {
                                        (isNumber(e.target.value) || e.target.value === '') && setValue(e.target.value);
                                        setError(null);
                                    }}
                                />
                                <NoMarginDivider />
                                {
                                    error &&
                                    <Text type="danger" style={{ textAlign: 'center', marginTop: '20px' }}>
                                        {error}
                                    </Text>
                                }
                            </Flex>
                        </Item>

                        <LoginButton type="primary" block htmlType="submit">
                            הבא
                        </LoginButton>
                    </Flex>
                </Form>
            </Flex>
        </Flex>
    )
};

const VerifyCodeView = () => {

    const [pinCode, setPinCode] = useState<string[]>(['', '', '', '']);
    const [error, setError] = useState<string | null>(null);
    const [resendCodeStatus, setResendCodeStatus] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);

    useEffect(() => {
        auth.error ? setError('קוד לא תקין') : setError(null);
    }, [auth.error]);

    useEffect(() => {
        if (resendCodeStatus && auth.secret && auth.verifyCode) {
            Toast.show({
                icon: 'success',
                content: 'קוד נשלח',
                duration: 2500
            });
        } else if (resendCodeStatus && auth.error) {
            setError('אירעה שגיאה בשליחת הקוד, אנא נסה שנית')
        }
    }, [resendCodeStatus]);

    const resendCode = useCallback(_.debounce(() => {
        if (auth.secret && !auth.loading) {
            dispatch(loginStart({ phone: auth.loginForm?.phone || '' }));
            setResendCodeStatus(true);
        }
    }, 500), [])

    const checkCode = () => {
        let valid = true;
        for (let c of pinCode) {
            if (c === '') {
                valid = false;
            }
        }
        setError(!valid ? 'קוד לא תקין' : null)
        return valid;
    };

    const onVerifyCodeClick = () => {
        setResendCodeStatus(false);
        if (!checkCode()) return;
        if (auth.secret) {
            dispatch(verifyCodeStart({
                code: pinCode.join(''),
                secret: auth.secret,
                phone: auth.loginForm?.phone || '',
            }));
        } else {
            setError('אירעה שגיאה, אנא נסה שנית')
        }
    }


    return (
        <Flex vertical justify='space-between' style={{ height: '100%', marginTop: '90px' }}>

            <StyledMainTitle level={4} style={{ marginBottom: '60px' }} >התחברות</StyledMainTitle>

            <Flex vertical gap={40} >
                <Text >טקסט תיאור</Text>

                <Flex vertical justify='space-between' style={{ minHeight: 'calc(100vh - 400px)', boxSizing: 'border-box' }} >
                    <Flex vertical gap={20} >
                        <PinInput
                            value={pinCode}
                            setValue={setPinCode}
                            disabled={auth.loading}
                            numOfDigits={4} />
                        {error &&
                            <Text type="danger" style={{ textAlign: 'center' }}>
                                {error}
                            </Text>}
                        <Button
                            shape="round"
                            type="link"
                            onClick={resendCode}
                            style={{ fontWeight: 500, padding: 0, marginTop: '20px' }}>
                            לא קיבלתי קוד
                        </Button>
                    </Flex>

                    <LoginButton
                        onClick={onVerifyCodeClick}
                        type="primary"
                        block
                        htmlType="submit">
                        הבא
                    </LoginButton>
                </Flex>
            </Flex>
        </Flex>
    )
};

const LoginForm = () => {

    const auth = useAppSelector(state => state.auth);
    const [view, setView] = useState<'phone' | 'pinCode'>('phone');

    useEffect(() => {
        if (auth.verifyCode) {
            setView('pinCode')
        }
    }, [auth.verifyCode]);

    return (
        <LoginContainer>
            <Image src={Logo} width={70} height={70} fit='fill' />
            {view === 'pinCode' ? <VerifyCodeView /> : <LoginView />}
        </LoginContainer>
    )
}

export default LoginForm;


const formatIsraeliPhoneNumber = (input: string) => {

    const numericInput = input.replace(/\D/g, '');
    let formattedNumber = numericInput;

    const israeliNumberRegex = /^0[1-9]\d{8}$/;
    if (israeliNumberRegex.test(numericInput)) {
        formattedNumber = `+972${numericInput.slice(1)}`;
    } else {
        formattedNumber = '';
    }

    return formattedNumber;
};
