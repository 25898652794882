import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IRestaurantState } from '../../../interfaces/store/restaurants-interfaces';

const initialState: IRestaurantState = {
    restaurants: [],
    chosenRestaurant: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanRestaurantsState = (state: IRestaurantState) => {
    return initialState;
};

const _fetchRestaurantsStart = (state: IRestaurantState) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchRestaurantsSuccess = (state: IRestaurantState, { payload }: PayloadAction<any>) => {
    state.restaurants = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchRestaurantsFailed = (state: IRestaurantState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _setChosenRestaurant = (state: IRestaurantState, { payload }: PayloadAction<any>) => {
    state.chosenRestaurant = payload;
}

const RestaurantsSlice = createSlice({
    name: 'restaurants',
    initialState,
    reducers: {
        cleanRestaurantsState: _cleanRestaurantsState,
        fetchRestaurantsStart: _fetchRestaurantsStart,
        fetchRestaurantsSuccess: _fetchRestaurantsSuccess,
        fetchRestaurantsFailed: _fetchRestaurantsFailed,
        setChosenRestaurant: _setChosenRestaurant,
    }
})

const { actions, reducer } = RestaurantsSlice;
export const {
    cleanRestaurantsState,
    fetchRestaurantsStart,
    fetchRestaurantsSuccess,
    fetchRestaurantsFailed,
    setChosenRestaurant
} = actions;

export default reducer;
