import React from 'react';
import { Flex, Form, Typography } from 'antd';
import { MentionsInput, Mention } from "react-mentions";
import { parameterslist } from './Parameters';
import { TextareaCounter } from '../StyledComponents';

const { Text } = Typography;
const { Item } = Form;

type Props = {
    textAreaVal: string,
    setTextAreaVal: Function,
}

let style = {
    input: {
        overflow: 'auto',
        height: 70,
    },
    highlighter: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: 70,
    },
}

const ParametersTextarea = (props: Props) => {

    const {
        textAreaVal,
        setTextAreaVal,
    } = props;

    return (
        <Item
            name="content"
            style={{ width: '100%', padding: "10px 0 10px 10px" }}
            rules={[{ required: true, message: 'שדה חובה' }]}
        >
            <div>
                <Flex vertical >
                    <Text style={{ padding: "10px 11px 6px" }}>תוכן</Text>

                    <MentionsInput
                        placeholder='טקסט'
                        rows={2}
                        style={style}
                        className='textarea-parameters'
                        value={textAreaVal}
                        dir='rtl'
                        customSuggestionsContainer={() => <span style={{ display: 'none' }} />}
                        onChange={(e) => setTextAreaVal(e.target.value)}>
                        <Mention
                            appendSpaceOnAdd={true}
                            style={{
                                direction: 'rtl',
                            }}
                            trigger="{{"
                            data={parameterslist}
                            displayTransform={(id, display) => `{{${display}}}`}
                            markup="{{__display__}}"
                        />
                    </MentionsInput>
                </Flex>

                <TextareaCounter type='secondary' >
                    {textAreaVal.length}
                </TextareaCounter>
            </div>
        </Item>
    )
}

export default ParametersTextarea;
