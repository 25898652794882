import axios, { AxiosInstance, HeadersDefaults } from 'axios';
import env, {exportConfigBeteabon as beteabonEnv} from '../config';


// declare var process: {
//     env: {
//         REACT_APP_STAGE: string
//     }
// }

interface CommonHeaderProperties extends HeadersDefaults {
    Authorization: string;
}

let headers = {
    client: 'web',
    clientVersion: env.APP_VERSION,
};

let beteabonHeaders = {
    client: 'web',
    clientVersion: beteabonEnv.APP_VERSION,
};

// if (process.env.REACT_APP_STAGE === 'int') {
//     headers = {
//         client: 'web',
//         clientVersion: env.APP_VERSION,
//     };
// }

axios.defaults.withCredentials = false

const instance = axios.create({
    baseURL: env.apiGateway.API_SERVER_URL,
    headers: headers,
});
instance.defaults.withCredentials = false


const beteabonInstance = axios.create({
    baseURL: beteabonEnv.apiGateway.API_SERVER_URL,
    headers: beteabonHeaders,
});
beteabonInstance.defaults.withCredentials = false;


export const updateToken = () => {
    instance.defaults.headers = {
        Authorization: localStorage.getItem('access_token')
    } as any

    beteabonInstance.defaults.headers = {
        Authorization: localStorage.getItem('access_token')
    } as any
}

export const removeToken = () => {
    instance.defaults.headers = {} as any
    beteabonInstance.defaults.headers = {} as any

}

// export const updateToken2fa = (accessToken: string) => {
//     instance.defaults.headers = {
//         Authorization: accessToken
//     } as any
// }


export {
    instance,
    beteabonInstance
};
