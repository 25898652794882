import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { ILoginForm } from '../../../interfaces/components/forms/login';
import { IAuthUserState } from '../../../interfaces/store/auth-user-interfaces'


const initialState: IAuthUserState = {
    user: null,
    loginForm: null,
    authenticated: false,
    loading: false,
    verifyCode: false,
    // verificationToken: null,
    secret: null,
    onStartUp: true,
    error: null,
    requestState: 'prepare',
    operation: null,
}


const _cleanState = (state: IAuthUserState) => {
    return initialState;
};

// const _resetVerifyCode = (state: IAuthUserState) => {
//     state.verifyCode = false;
// };

// const _loadStart = (state: IAuthUserState) => {
//     state.loading = true;
//     state.error = null;
// };

const _failed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
};

// const _success = (state: IAuthUserState) => {
//     state.loading = false;
// };


const _authCheckStart = (state: IAuthUserState) => {
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _authCheckSuccess = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.user = payload.user;
    state.onStartUp = false;
    state.authenticated = true;
    state.loading = false;
    state.error = null;
}

const _authCheckFailed = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.authenticated = false;
    state.onStartUp = false;
    state.loading = false;
    state.error = payload;
}

const _loginStart = (state: IAuthUserState, { payload }: PayloadAction<ILoginForm>) => {
    state.loginForm = payload;
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _loginSuccess = (state: IAuthUserState, { payload }: PayloadAction<{ secret: string }>) => {
    state.verifyCode = true;
    state.secret = payload.secret;
    state.loading = false;
    state.error = null;
}

const _loginFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.authenticated = false;
    state.loading = false;
    state.error = payload;
}

const _verifyCodeStart = (state: IAuthUserState, { payload }: PayloadAction<{ code: string, secret: string, phone: string }>) => {
    state.user = null;
    state.authenticated = false;
    state.loading = true;
    state.error = null;
}

const _verifyCodeSuccess = (state: IAuthUserState, { payload }: PayloadAction<any>) => {
    state.user = payload.user
    state.authenticated = true;
    state.secret = null; 
    state.verifyCode = false;
    state.loading = false;
    state.error = null;
}

const _verifyCodeFailed = (state: IAuthUserState, { payload }: PayloadAction<IError>) => {
    state.authenticated = false;
    state.loading = false;
    state.error = payload;
}

const _authLogoutStart = (state: IAuthUserState) => {
    state.error = null;
    state.loading = true;
};

const _authLogoutSuccess = (state: IAuthUserState) => {
    state.user = null;
    state.authenticated = false;
    state.error = null;
    state.loading = false;
    state.onStartUp = false;
};

// const _resendCodeStart = (state: IAuthUserState, { payload }: PayloadAction<{ phone: string }>) => {
//     state.loading = true;
// };

// const _scheduleRefresh = (state: IAuthUserState, { payload }: PayloadAction<any>) => { };


const AuthUserSlice = createSlice({
    name: 'auth-user',
    initialState,
    reducers: {
        cleanState: _cleanState,
        authCheckStart: _authCheckStart,
        authCheckSuccess: _authCheckSuccess,
        authCheckFailed: _authCheckFailed,
        loginStart: _loginStart,
        loginSuccess: _loginSuccess,
        loginFailed: _loginFailed,
        verifyCodeStart: _verifyCodeStart,
        verifyCodeSuccess: _verifyCodeSuccess,
        verifyCodeFailed: _verifyCodeFailed,
        authLogoutStart: _authLogoutStart,
        authLogoutSuccess: _authLogoutSuccess,
        // loadStart: _loadStart,
        failed: _failed,
        // success: _success,
        // scheduleRefresh: _scheduleRefresh,
        // resetVerifyCode: _resetVerifyCode,
        // resendCodeStart: _resendCodeStart
    }
})

const { actions, reducer } = AuthUserSlice;
export const {
    cleanState,
    // loadStart,
    failed,
    // success,
    authCheckStart,
    authCheckSuccess,
    authCheckFailed,
    loginStart,
    loginSuccess,
    loginFailed,
    verifyCodeStart,
    verifyCodeSuccess,
    verifyCodeFailed,
    authLogoutStart,
    authLogoutSuccess,
    // scheduleRefresh,
    // resetVerifyCode,
    // resendCodeStart
} = actions;

export default reducer;
