import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontSize: 16,
    controlHeight: 49,
    fontFamily: 'Heebo-Regular',
  },
  components: {
    Typography: {
      titleMarginBottom: '0px',
      titleMarginTop: '0px',
    },
    Form: {
      itemMarginBottom: 20,
    },

    Collapse: {
      headerPadding: 0,
      contentPadding: 0,
    },
  },
};

export default theme;