import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { authCheckStart } from '../store/features/auth-user/auth-user-slice';
import { Flex, Spin } from 'antd';
import NavigationSwitch from '../components/navigation/NavigationSwitch';
import LoginNavigation from '../components/login/LoginNavigation';
import { useAppDispatch, useAppSelector } from '../store/config';

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector(state => state.auth);

  useEffect(() => {
    dispatch(authCheckStart())
  }, [dispatch]);

  let view = (
    <Flex justify='center' align='center' style={{ height: '100vh' }}>
      <Spin />
    </Flex>
  );

  if (auth.authenticated && !auth.onStartUp && auth.user) {
    view = (
      <Router>
        <NavigationSwitch />
      </Router>
    );
  }
  if (!auth.authenticated && !auth.onStartUp) {
    view = (
      <LoginNavigation />
    );
  }

  return (
    <>
      {view}
    </>
  );
}

export default App;
