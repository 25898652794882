import React, { useMemo, useState } from 'react';
import { Button, Flex, Typography } from 'antd';
import { Popup, SearchBar, CheckList } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons'
import { ReactComponent as Circle } from '../../../assets/icons/Circle.svg';
import { PaddedContainer, StyledMainTitle } from '../../common/StyledComponents';

const { Text } = Typography;

type Props = {
    title: string,
    list: any[],
    initValue: any[],
    onChange: Function,
    name: string,
    visible: boolean,
    setVisible: Function,
    height?: string
}

const ChooseFromListPopup = (props: Props) => {

    const {
        title,
        list,
        onChange,
        name,
        visible,
        setVisible,
        height,
        initValue
    } = props;

    const [selected, setSelected] = useState<any[]>(initValue || [])
    const [searchText, setSearchText] = useState('')

    const filteredItems = useMemo(() => {
        if (searchText) {
            return list.filter(item => item.name.includes(searchText))
        } else {
            return list
        }
    }, [list, searchText])

    return (
        <Flex vertical style={{ width: '100%' }}>
            <Popup
                visible={visible}
                onMaskClick={() => {
                    setVisible(false);
                    setSelected([...initValue]);
                }}
                maskStyle={{ background: '#243E6333' }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    height: height || '75vh',
                }} >

                <PaddedContainer
                    justify="space-between"
                    align='center'
                    style={{ paddingBottom: '0px' }} >
                    <Text onClick={() => {
                        setVisible(false);
                        setSelected([...initValue]);
                    }} >
                        ביטול
                    </Text>
                    <StyledMainTitle level={4}>{title}</StyledMainTitle>
                    <Flex style={{ margin: 0 }}>
                        <Button
                            htmlType="submit"
                            shape="round"
                            type="link"
                            onClick={() => {
                                setVisible(false);
                                onChange(name, selected);
                            }}
                            style={{ fontWeight: 500, padding: '11.5px  0px' }} >
                            המשך
                        </Button>
                    </Flex>
                </PaddedContainer>

                <PaddedContainer
                    vertical
                    justify="space-between"
                    align='center'
                    style={{ width: '100%' }}>
                    <SearchBar
                        placeholder='חיפוש'
                        value={searchText}
                        onChange={v => { setSearchText(v) }}
                        style={{ width: '100%', "--height": '40px', marginBottom: '15px' }}
                    />
                    <Flex style={{ width: '100%' }}>
                        <CheckList
                            extra={active =>
                                active ? <CheckCircleFill /> : <Circle />
                            }
                            onChange={val => { setSelected([...val]); }}
                            value={[...selected]}
                            multiple
                            style={{ width: '100%', maxHeight: '50vh', overflowY: 'scroll' }}
                            defaultValue={selected ? selected : []}
                        >
                            {filteredItems.map(item => (
                                <CheckList.Item key={item.id} value={item.name}>
                                    {item.name}
                                </CheckList.Item>
                            ))}
                        </CheckList>
                    </Flex>
                </PaddedContainer>
            </Popup>
        </Flex>
    )
}

export default ChooseFromListPopup;



