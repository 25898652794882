import { instance as axiosInstance } from '../../config/axios';
import { ILoginForm } from '../../interfaces/components/forms/login';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError } from './utils/common';

const phoneLogin = async (params: ILoginForm) => {
    try {
        let url = getParamsUrl(ServerRoutes.auth.phoneLogin, { phone: encodeURIComponent(params.phone) });
        const { status, data } = await axiosInstance.get(url);

        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'User not found';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const verify2faCode = async (code: string, secret: string, phone: string) => {
    try {

        const params = {
            grant_type: "sms_otp",
            code: code,
            secret: secret,
            phone: phone,
        }
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.verifyCode, params);

        return { status, data: data.data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'Wrong code';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const refreshTokenLogin = async (refresh_token: string) => {
    try {
        const { status, data } = await axiosInstance.post(ServerRoutes.auth.verifyCode, { refresh_token, grant_type: 'refresh_token' });
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 400:
                message = 'Token is expired';
                break;
            case 401:
                message = 'Invalid refresh token';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const logout = async (token: string) => {
    try {
        const url = ServerRoutes.auth.logout + '?refresh_token_hint=' + token;
        const { status, data } = await axiosInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const authUserApi = {
    phoneLogin,
    verify2faCode,
    refreshTokenLogin,
    logout,
}

export default authUserApi;