import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchMessagesFailed, fetchMessagesStart, fetchMessagesSuccess } from './messages-slice';
import { IGetMessagesParams } from '../../../interfaces/api/messages';
import messagesApi from '../../api/messages-api';

function* fetchMessages(action: PayloadAction<{ params: IGetMessagesParams; }>): any {
    try {
        const { params } = action.payload;
        const { data, status, error } = yield call(messagesApi.getMessages, params); 
        
        if (error) {
            yield put(fetchMessagesFailed(error));
            return;
        }
        
        yield put(fetchMessagesSuccess(data));
    
    } catch (error: any) {
        yield put(fetchMessagesFailed(error));
    }
}

function* messagesSaga() {
    yield takeLatest(fetchMessagesStart.type, fetchMessages);
}

export default messagesSaga;