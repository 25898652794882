import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Flex, Image, Spin, Switch, Typography } from 'antd';
import { Dialog, SwipeAction, Toast } from 'antd-mobile';
import { SwipeActionRef } from 'antd-mobile/es/components/swipe-action'
import Routes from '../../routes';
import { NoMarginDivider, PaddedContainer, StyledMainTitle, StyledTitle, PageOpacitySpinner } from '../common/StyledComponents';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { cleanEditMessageState, deleteMessageStart, updateMessageStart } from '../../store/features/edit-message/edit-message-slice';
import { fetchMessagesStart } from '../../store/features/messages/messages-slice';
import SideNav from './SideNav';
import ChooseRestaurant from './ChooseRestaurant';
import BurgerMenu from '../../assets/icons/menu-burger.svg';
import AddNew from '../../assets/icons/add-new.svg';
import Empty from '../../assets/images/empty-messages.svg';
import { IMessage } from '../../interfaces/models/message';
import { IUpdateMessageBody } from '../../interfaces/api/messages';

const { Text } = Typography;

const Messages = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { messages, error, loading } = useAppSelector(state => state.messages);
    const {
        error: editMessageError,
        loading: editMessageLoading,
        operation,
        requestState
    } = useAppSelector(state => state.editMessage);
    const { chosenRestaurant } = useAppSelector(state => state.restaurants);
    const { user } = useAppSelector(state => state.auth);

    const ref = useRef<SwipeActionRef>(null);
    const [openSideNav, setOpenSideNav] = useState(false);

    useEffect(() => {
        if (error || editMessageError) {
            Toast.show({
                icon: 'fail',
                content: 'אירעה שגיאה',
                duration: 2500
            });
        }
    }, [error, editMessageError]);

    useEffect(() => {
        if (requestState === 'finish' && (['delete', 'update'].includes(operation || '')) && chosenRestaurant && user) {
            dispatch(fetchMessagesStart({
                params: {
                    restorauntId: chosenRestaurant.account.id,
                    buserId: user._id
                }
            }))
            dispatch(cleanEditMessageState());
        }
    }, [operation, requestState]);

    useEffect(() => {
        if (chosenRestaurant && user) {
            dispatch(cleanEditMessageState());
            dispatch(fetchMessagesStart({
                params: {
                    restorauntId: chosenRestaurant.account.id,
                    buserId: user._id
                }
            }))
        }
    }, [chosenRestaurant]);

    const onSwitchChange = (checked: boolean, message: IMessage) => {

        let updateMessageBody: IUpdateMessageBody = {
            messageId: message._id,
            data: {
                status: checked ? 'active' : 'draft',
                title: message.title,
                content: message.content,
                restaurants: message.restaurants,
                useDates: message.useDates,
                restrictions: message.restrictions,
            }
        }

        if (message.startDate) updateMessageBody.data['startDate'] = message.startDate;
        if (message.endDate) updateMessageBody.data['endDate'] = message.endDate;
        if (message.brands) updateMessageBody.data['brands'] = message.brands;
        if (message.areas) updateMessageBody.data['areas'] = message.areas;

        dispatch(updateMessageStart(updateMessageBody))
    };

    return (
        <Flex
            vertical
            style={{ width: '100%' }}  >

            {loading || editMessageLoading ?
                <PageOpacitySpinner justify='center' align='center'>
                    <Spin />
                </PageOpacitySpinner> : null
            }

            <SideNav openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} />
            <ChooseRestaurant />

            <PaddedContainer
                justify="space-between"
                align='center'
                style={{ paddingTop: '30px' }} >
                <Image preview={false} src={BurgerMenu} onClick={() => setOpenSideNav(true)} />
                <StyledMainTitle level={4}>מסרים</StyledMainTitle>
                <Image
                    onClick={() => navigate(Routes.Messages.MessagePage.replace(':id', 'add-new'))}
                    preview={false}
                    src={AddNew} />
            </PaddedContainer>

            <NoMarginDivider />

            <Flex vertical style={{ margin: '10px 0 30px' }}>
                {(messages && messages.length > 0) ? messages.map((message) => {
                    return (
                        <SwipeAction
                            ref={ref}
                            key={message._id}
                            leftActions={[
                                {
                                    key: 'delete',
                                    text: 'מחיקה',
                                    color: 'danger',
                                    onClick: async () => {
                                        await Dialog.confirm({
                                            content: <div style={{ textAlign: 'center' }}>
                                                האם אתה בטוח שברצונך למחוק את המסר <b>{message.title}</b>?
                                            </div>,
                                            onConfirm: () => {
                                                dispatch(deleteMessageStart({ messageId: message._id }));
                                            },
                                            confirmText: "מחיקה",
                                            cancelText: "ביטול",
                                        })
                                        ref.current?.close()
                                    },
                                },
                            ]}

                        >
                            <PaddedContainer vertical style={{ paddingBottom: '0', paddingTop:'30px' }}>
                                <Flex
                                    justify='space-between'
                                    align='center'>
                                    <Flex vertical onClick={() => navigate(Routes.Messages.MessagePage.replace(':id', message._id))}>
                                        <StyledTitle level={5}> {message.title}</StyledTitle>
                                        <Text
                                            style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                            type="secondary">
                                            {message.content}
                                        </Text>
                                    </Flex>
                                    <Flex vertical gap={10}>
                                        <Flex vertical align="end">
                                            <Switch
                                                value={message.status === 'active'}
                                                onChange={(checked) => onSwitchChange(checked, message)}
                                            />
                                        </Flex>
                                        {
                                            message.startDate && new Date(message.startDate) > new Date() ? (
                                                <Text style={{ fontSize: '14px' }}>
                                                    {" התחלה: " +
                                                        new Date(message.startDate).toLocaleDateString('he-IL')}
                                                </Text>
                                            ) : null
                                        }
                                    </Flex>
                                </Flex >
                                <Divider style={{ margin: '12px 0 0 0' }} />

                            </PaddedContainer>
                        </SwipeAction>
                    )
                }) :
                    <Flex gap={20} vertical style={{ height: '70vh' }} justify='center' align='center'>
                        <Image height={284} preview={false} src={Empty} />
                        <Text type="secondary" style={{ marginTop: '35px', fontSize: '18px' }}>אין מסרים</Text>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}

export default Messages;
