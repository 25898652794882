import React, { useEffect, useState } from 'react';
import { Flex, Image, Typography, Switch, Collapse, FormInstance } from 'antd';
import LeftArrow from '../../../assets/icons/Left.svg';
import ChooseFromListPopup from './ChooseFromListPopup';
import { DisplayLimitRowBtn, LimitRow, StyledFormItem } from '../StyledComponents';
import messagesApi from '../../../store/api/messages-api';
import { useAppSelector } from '../../../store/config';

const { Text } = Typography;

export const DisplayLimit = (props: { onChange: Function, form: FormInstance<any> }) => {

    const { onChange, form } = props;

    const chosenRestaurantGuid = useAppSelector(state => state.restaurants.chosenRestaurant?.account.guid);

    const [brandPopupOpen, setBrandPopupOpen] = useState<boolean>(false);
    const [areasPopupOpen, setAreasPopupOpen] = useState<boolean>(false);

    const [brandList, setBrandList] = useState<any[]>([]);
    const [areasList, setAreasList] = useState<any[]>([]);

    useEffect(() => {
        messagesApi.getBrands(chosenRestaurantGuid || '').then((res: any) => {
            if (res.data.data.data) setBrandList(res.data.data.data);
        });

        messagesApi.getAreas(chosenRestaurantGuid || '').then((res: any) => {
            if (res.data.data.data) setAreasList(res.data.data.data);
        });

    }, []);

    return (

        <Collapse
            ghost
            collapsible="icon"
            expandIconPosition="end"
            onChange={(val) => { form.setFieldValue('restrictions', val.length > 0) }}
            defaultActiveKey={form.getFieldValue('restrictions') ? ['1'] : []}
            expandIcon={({ isActive }) => <Switch value={isActive} />}
            items={[
                {
                    key: '1',
                    label: <Text>הגבלת תצוגה</Text>,
                    children:
                        <Flex vertical>

                            <LimitRow justify='space-between' align='center' >
                                <Text>מותגים</Text>
                                <ChooseFromListPopup
                                    title={'בחירת מותגים'}
                                    list={brandList}
                                    onChange={onChange}
                                    name={"brands"}
                                    initValue={form.getFieldValue('brands') || []}
                                    visible={brandPopupOpen}
                                    setVisible={setBrandPopupOpen} />
                                <Flex gap={12}>
                                    <StyledFormItem
                                        name="brands"
                                    >
                                        <DisplayLimitRowBtn
                                            onClick={() => { setBrandPopupOpen(true) }}
                                            gap={20}
                                            align='center' >
                                            {
                                                form.getFieldValue('brands')?.length > 0 ?
                                                    form.getFieldValue('brands')?.length === 1 ? form.getFieldValue('brands')[0] :
                                                        form.getFieldValue('brands')?.length + ' מותגים'
                                                    : 'בחר מותגים'
                                            }
                                            <Image preview={false} src={LeftArrow} style={{ opacity: "0.3", rotate: '180deg', height: '19px' }} />
                                        </DisplayLimitRowBtn>
                                    </StyledFormItem>
                                </Flex>
                            </LimitRow>

                            <LimitRow justify='space-between' align='center' >
                                <Text>אזורי חלוקה</Text>
                                <ChooseFromListPopup
                                    title={'בחירת אזורי חלוקה'}
                                    initValue={form.getFieldValue('areas') || []}
                                    list={areasList}
                                    onChange={onChange}
                                    name={"areas"}
                                    visible={areasPopupOpen}
                                    setVisible={setAreasPopupOpen} />
                                <Flex gap={12}>
                                    <StyledFormItem
                                        name="areas"
                                    >
                                        <DisplayLimitRowBtn
                                            gap={20}
                                            align='center'
                                            onClick={() => { setAreasPopupOpen(true) }}
                                        >
                                            {
                                                form.getFieldValue('areas')?.length > 0 ?
                                                    form.getFieldValue('areas')?.length === areasList.length ? 'הכל' :
                                                        form.getFieldValue('areas')?.length === 1 ? form.getFieldValue('areas')[0] :
                                                            form.getFieldValue('areas')?.length + ' אזורי חלוקה'
                                                    : 'בחר אזורי חלוקה'
                                            }
                                            <Image
                                                preview={false}
                                                src={LeftArrow}
                                                style={{ opacity: "0.3", rotate: '180deg', height: '19px' }} />
                                        </DisplayLimitRowBtn>
                                    </StyledFormItem>
                                </Flex>
                            </LimitRow>

                        </Flex>,
                },
            ]}
        />
    )
}

