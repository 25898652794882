import React from 'react';
import { Flex, Switch, Collapse, Typography, FormInstance } from 'antd';
import { MessageDatePicker, MessageTimePicker } from './MessageDateTimePickers';
import { StyledFormItem, LimitRow } from '../StyledComponents';
import { getNowTime } from '../Message';


const { Text } = Typography;

export const TimeLimit = (props: { onChange: Function, form: FormInstance<any> }) => {

    const { onChange, form } = props;

    return (
        <Collapse
            ghost
            collapsible="icon"
            expandIconPosition="end"
            onChange={(val) => { form.setFieldValue('useDates', val.length > 0) }}
            defaultActiveKey={form.getFieldValue('useDates') ? ['1'] : []}
            expandIcon={({ isActive }) => <Switch value={isActive} />}
            items={[
                {
                    key: '1',
                    label: <Text>הגבלת זמן</Text>,
                    children:
                        <Flex vertical>

                            <LimitRow justify='space-between' align='center' >
                                <Text>התחלה</Text>
                                <Flex gap={12}>
                                    <StyledFormItem
                                        name="startDate"
                                    >
                                        <MessageDatePicker onChange={onChange} name={'startDate'} initVal={form.getFieldValue('startDate') || new Date()} />
                                    </StyledFormItem>
                                    <StyledFormItem
                                        name="startTime"
                                    >
                                        <MessageTimePicker onChange={onChange} name={'startTime'} initVal={form.getFieldValue('startTime') || getNowTime()} />
                                    </StyledFormItem>
                                </Flex>
                            </LimitRow>

                            <LimitRow justify='space-between' align='center' >
                                <Text>סיום</Text>
                                <Flex gap={12}>
                                    <StyledFormItem
                                        name="endDate"
                                    >
                                        <MessageDatePicker onChange={onChange} name={'endDate'} initVal={form.getFieldValue('endDate')} />
                                    </StyledFormItem>
                                    <StyledFormItem
                                        name="endTime"
                                    >
                                        <MessageTimePicker onChange={onChange} name={'endTime'} initVal={form.getFieldValue('endTime')} />
                                    </StyledFormItem>
                                </Flex>
                            </LimitRow>

                        </Flex>,
                },
            ]}
        />

    )
}
