import styled from 'styled-components';
import { Button, Input } from 'antd';


export const PinCodeInput = styled(Input)`
    width: 66px;
    height: 73px;
    background-color: rgb(245, 245, 245);
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 27px;
    padding: 14px;
    &:hover {
        background-color: rgb(245, 245, 245);
    }
`;

export const LoginContainer = styled.div`
    padding: 40px 20px 50px;
    min-height: 100vh;
    box-sizing: border-box ;
`;


export const LoginButton = styled(Button)`
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 52px;
    max-width: 350px;
`;

