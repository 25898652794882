import { PayloadAction } from '@reduxjs/toolkit';
import { call, fork, put, takeLatest, delay } from 'redux-saga/effects';
import { jwtDecode } from "jwt-decode";
import { DecodedData } from './../../../utils/utils';
import { removeToken, updateToken } from './../../../config/axios';
import { IBaseResponse } from './../../../interfaces/base';
import {
    authCheckStart,
    authCheckFailed,
    authCheckSuccess,
    loginStart,
    loginFailed,
    loginSuccess,
    verifyCodeStart,
    verifyCodeFailed,
    verifyCodeSuccess,
    authLogoutSuccess,
    failed,
    authLogoutStart
} from './auth-user-slice';
import authApi from '../../api/auth-user-api';
import { getTokenRefreshTime } from '../../../utils/utils';
import { ILoginForm } from '../../../interfaces/components/forms/login';
import { setChosenRestaurant } from '../restarurants/restaurants-slice';
import { cleanMessagesState } from '../messages/messages-slice';

function* authCheckState(): any {
    console.log('Auth check');

    const chosenRestaurant = localStorage.getItem('chosenRestaurant');
    yield put(setChosenRestaurant(chosenRestaurant ? JSON.parse(chosenRestaurant): null));

    try {
        const token = localStorage.getItem('access_token');
        const id_token = localStorage.getItem('id_token');
        const refresh_token = localStorage.getItem('refresh_token');
        if (!token || !refresh_token) {
            localStorage.clear();
            return yield put(authLogoutSuccess());
        }
        const decoded: DecodedData = jwtDecode(token);
        const expiration = decoded.exp;
        const refreshTime = getTokenRefreshTime(token);

        if ((!expiration || (expiration - 300) < new Date().getTime() / 1000) && refresh_token) {
            const { status, data, error } = yield call(authApi.refreshTokenLogin, refresh_token);
            if (status === 200 && data.access_token) {
                const { access_token, id_token, refresh_token, expires_in } = data;
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('id_token', id_token);
                localStorage.setItem('refresh_token', refresh_token);
                localStorage.setItem('expires_in', JSON.stringify(expires_in));

                yield call(updateToken);
                yield fork(scheduleTokenRefresh, refreshTime);
                const user = getDecodedInfo(id_token);
                if (user) {
                    return yield put(authCheckSuccess({ user }));
                } else {
                    localStorage.clear();
                    return yield put(authCheckFailed({ message: 'Invalid token provided' }));
                }
            } else {
                localStorage.clear();
                removeToken();
                return yield put(authLogoutSuccess());
            }
        } else {
            yield call(updateToken);
            yield fork(loadTokens);
            const user = getDecodedInfo(id_token)
            if (user) {
                return yield put(authCheckSuccess({ user }));
            } else {
                localStorage.clear();
                return yield put(authCheckFailed({ message: 'Invalid token provided' }));
            }
        }
    } catch (error: any) {
        return yield put(authCheckFailed(error));
    }
}

interface phoneloginResponse extends IBaseResponse {
    data: {
        secret: string,
    }
}

function* sendPhoneLoginRequest(action: PayloadAction<ILoginForm>): any {
    try {
        const { phone } = action.payload;
        const { data, status, error }: phoneloginResponse = yield call(authApi.phoneLogin, { phone });

        if (error) {
            yield put(loginFailed(error));
            return;
        }
        yield put(loginSuccess(data));
    } catch (error: any) {
        yield put(loginFailed(error));
    }
}

function* send2faRequest(action: PayloadAction<{ code: string; secret: string; phone: string; }>) {
    try {
        const { code, secret, phone } = action.payload;
        const { data, status, error }: IBaseResponse = yield call(authApi.verify2faCode, code, secret, phone);

        if (error) {
            yield put(verifyCodeFailed(error));
            return;
        }

        if (status === 200 && data.access_token) {

            const { access_token, id_token, refresh_token, expires_in } = data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('expires_in', JSON.stringify(expires_in));

            yield call(updateToken);

            const time = getTokenRefreshTime(data.access_token);
            yield fork(scheduleTokenRefresh, time);

            const user = getDecodedInfo(id_token)
            if (user) {
                yield put(verifyCodeSuccess({ user: user }));
            } else {
                localStorage.clear();
                yield put(verifyCodeFailed({ message: 'Invalid token provided' }));
            }
        } else {
            localStorage.clear();
        }
    } catch (error: any) {
        yield put(verifyCodeFailed(error))
    }
}

function* authUserLogout(): any {
    try {
        let refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            const { data, status, error }: IBaseResponse = yield call(authApi.logout, refreshToken);

            if (status === 200 && data.data.ok) {
                localStorage.clear();
                removeToken();

                yield put(cleanMessagesState());
                yield put(setChosenRestaurant(null));
                return yield put(authLogoutSuccess());
            }
            else {
                return yield put(failed(error));
            }
        }
        localStorage.clear();
        removeToken();

        yield put(cleanMessagesState());
        yield put(setChosenRestaurant(null));
        return yield put(authLogoutSuccess());
    } catch (error: any) {
        yield put(failed({ message: error.message }));
    }
};

export const getDecodedInfo = (token: string | null) => {
    try {
        if (token) {
            const decoded = jwtDecode(token);
            return decoded
        }
        return null;
    } catch (error) {
        return null;
    }
}

function* loadTokens() {
    console.log('Update auth');
    try {
        let refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
            return 'refresh token not found';
        }

        const { status, data, error } = yield call(authApi.refreshTokenLogin, refreshToken);
        if (status === 200 && data.access_token) {
            const { access_token, id_token, refresh_token, expires_in } = data;
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('id_token', id_token);
            localStorage.setItem('refresh_token', refresh_token);
            localStorage.setItem('expires_in', JSON.stringify(expires_in));
            yield call(updateToken);

            const time = getTokenRefreshTime(data.access_token);
            yield fork(scheduleTokenRefresh, time);
            // return data;
        }
        return error;
    } catch (error) {
        yield put(authUserLogout());
        return error;
    }
}

function* scheduleTokenRefresh(timer: number): any {
    console.log('Refresh in ' + timer);
    try {
        yield delay(timer);
        const error = yield call(loadTokens);
        if (error) {
            yield put(authUserLogout())
        }
    } catch (error) {
        yield put(authUserLogout());
        return;
    }

}


function* authUserSaga() {
    yield takeLatest(loginStart.type, sendPhoneLoginRequest);
    yield takeLatest(verifyCodeStart.type, send2faRequest)
    yield takeLatest(authCheckStart.type, authCheckState);
    yield takeLatest(authLogoutStart.type, authUserLogout);
}

export default authUserSaga;