import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Flex, Image, Typography } from 'antd';
import { Popup } from 'antd-mobile';
import { UserOutlined } from '@ant-design/icons';
import Routes from '../../routes';
import Close from '../../assets/icons/close.svg';
import LogOut from '../../assets/icons/log-out.svg';
import Phone from '../../assets/icons/phone-call.svg';
import { useAppSelector } from '../../store/config';


const { Text, Title } = Typography;


const SideNav = (props: { openSideNav: boolean, setOpenSideNav: (openSideNav: boolean) => void }) => {

    const { openSideNav, setOpenSideNav } = props;
    const { chosenRestaurant } = useAppSelector(state => state.restaurants);
    const navigate = useNavigate();

    return (
        <Popup
            visible={openSideNav}
            onMaskClick={() => {
                setOpenSideNav(false)
            }}
            position='right'
            maskStyle={{ background: '#1677FF0D' }}
            bodyStyle={{
                width: '60vw',
                padding: '44px 27px 20px 10px',
                boxShadow: '-1px 0px 6px 0px rgba(0, 0, 0, 0.05)'
            }}
        >

            <Flex vertical gap={20} >
                <Flex>
                    <Image
                        onClick={() => setOpenSideNav(false)}
                        preview={false}
                        height={24}
                        style={{ alignSelf: 'flex-end' }}
                        src={Close} />
                </Flex>

                <Flex gap={12} style={{ padding: '30px 0' }} align='center'>
                    <Avatar size={48} icon={<UserOutlined />} />
                    <Title style={{ margin: '0' }} level={5}>{chosenRestaurant?.account.name}</Title>
                </Flex>

                <Flex vertical gap={10} style={{ paddingRight: '10px' }}>
                    <Button
                        type="link"
                        href={'tel:+4733378901'} ///////// change to support phone number
                        style={{ padding: '0' }}>
                        <Flex gap={7}>
                            <Image
                                preview={false}
                                height={24}
                                src={Phone} />
                            <Text> תמיכה</Text>
                        </Flex>
                    </Button>

                    <Flex gap={7} onClick={() => { navigate(Routes.Auth.logout) }}>
                        <Image
                            preview={false}
                            height={24}
                            src={LogOut} />
                        <Text> התנתקות</Text>
                    </Flex>
                </Flex>

            </Flex>
        </Popup>
    )
}

export default SideNav;