import React, { useState } from 'react';
import { Button, Form, FormInstance } from 'antd';
import ChooseFromListPopup from './ChooseFromListPopup';
import { ParametersDisplay } from '../StyledComponents';
import { NoMarginDivider } from '../../common/StyledComponents';


const { Item } = Form;

export const Parameters = (props: { chosenParameters: string[], setChosenParameters: any }) => {

    const { chosenParameters, setChosenParameters } = props;
    const [parametersPopupOpen, setParametersPopupOpen] = useState<boolean>(false);

    const onParametersChange = (name: string, value: string[]) => {
        setChosenParameters(value);
    }

    return (
        <>
            <ChooseFromListPopup
                title={'הוספה'}
                list={parameterslist}
                onChange={onParametersChange}
                name={'parameters'}
                initValue={chosenParameters}
                visible={parametersPopupOpen}
                setVisible={setParametersPopupOpen}
                height='55vh' />
            <Button
                type="primary"
                block
                ghost
                style={{ marginBottom: '10px' }}
                onClick={(e) => { setParametersPopupOpen(true) }}>
                פרמטרים נוספים
            </Button>

            {
                chosenParameters.length > 0 ?
                    <>
                        <ParametersDisplay
                            options={
                                chosenParameters.map((item: any) => {
                                    return { label: item, value: item }
                                }) || []}
                            
                            defaultValue={parameterslist.map((item: any) => {
                                return item.name
                            }) || []}
                            multiple
                        />
                        <NoMarginDivider />
                    </>
                    : null
            }

        </>
    )
}


export const parameterslist: any[] = [
    {
        name: "שם פרטי",
        id: "name",
    },
    {
        name: "שם החברה",
        id: "companyName",
    },
];

