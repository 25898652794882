import styled from 'styled-components';
import { Button, Flex, Form, Typography } from 'antd';
import { Selector } from 'antd-mobile'

const { Item } = Form;
const { Text } = Typography;


export const FlexShadow = styled(Flex)`
    box-shadow: 0px 0px 6.2px rgba(0, 0, 0, 0.10);
    margin-bottom: 20px; 
`;

export const TextareaCounter = styled(Text)`
    position: absolute;
    bottom: -20px;
    left: 5px;
    font-size: 12px;
`;

export const StyledFormItem = styled(Item)`
    margin: 0;
`;

export const ParametersDisplay = styled(Selector)`
    width: 100%; 
    pointer-events: none;
    margin-bottom: 20px;
`;

export const LimitRow = styled(Flex)`
    width: 100%;
    background: #f2f7ff;
    padding: 6px 10px;
    border-bottom: 1px solid #EEE;
`;

export const DisplayLimitRowBtn = styled(Flex)`
    color: #999999;
    font-size: 14px;
`;

export const TimeLimitRowBtn = styled(Button)`
    border: none;
    font-size: 16px;
    border-radius: 6px;
    height: 36px;
    padding: 0 12px;
`;

