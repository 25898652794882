
const auth = {
    phoneLogin: '/api/v1/auth/registrationOtp',
    verifyCode: '/api/v1/auth/token',
    logout: '/api/v1/auth/logout',
}

const messages = {
    getMessages: '/api/v1/messages/all',
    getMessage: '/api/v1/messages/:id',
    getBrands: '/api/v1/brands?account=<account guid>',
    getAreas: '/api/v1/brands/areas?account=<account guid>',
    createMessage: '/api/v1/messages/create',
    updateMessage: '/api/v1/messages/:id',
    deleteMessage: '/api/v1/messages/:id',
}


const restaurants = {
    getRestaurants: '/api/v1/account/get/restaurants',
}

const ServerRoutes = {
    auth,
    messages,
    restaurants
}

export default ServerRoutes;