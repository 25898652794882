import { beteabonInstance } from '../../config/axios';
import ServerRoutes from './routes';
import { handleAxiosError } from './utils/common';

const getRestaurants = async () => {
    try {

        const { status, data } = await beteabonInstance.get(ServerRoutes.restaurants.getRestaurants);
        // const { status, data } = await beteabonInstance.get('https://test.beteabon.co.il/api/v1/profile/get_phone_profile.php?phone=+972509114569');

        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}


const restaurantsApi = {
    getRestaurants,
}

export default restaurantsApi;