import styled from 'styled-components';
import { Divider, Flex, Typography } from 'antd';

const { Title } = Typography;

export const StyledMainTitle = styled(Title)`
    margin: 0;
    font-size: 18px!important;
    font-weight: 700!important;
    text-align: center;
`;

export const StyledTitle = styled(Title)`
    margin: 0;
`;

export const PaddedContainer = styled(Flex)`
    padding: 20px 13px 10px;
`;

export const NoMarginDivider = styled(Divider)`
    margin: 0;
`;

export const MarginDivider = styled(Divider)`
     margin: 12px 0;
`;

export const PageOpacitySpinner = styled(Flex)`
    height: 100vh;
    background: #ffffffa6;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 5;
`;