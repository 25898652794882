
// declare var process: {
//     env: {
//         REACT_APP_STAGE: string
//     }
// }
const dev = {
    apiGateway: {
        API_SERVER_URL: "https://backapi.shareloc.co.il",
        IMAGE_STORAGE_URL: '',
        FILES_STORAGE_URL: '',
        DISTRIBUTION_STORAGE_URL: '',
        CLIENT_DOMAIN_URL: "",
    }
};

const int = {
    apiGateway: {
        API_SERVER_URL: "",
        IMAGE_STORAGE_URL: '',
        FILES_STORAGE_URL: '',
        DISTRIBUTION_STORAGE_URL: '',
        CLIENT_DOMAIN_URL: "",
    }
};

const prod = {
    apiGateway: {
        API_SERVER_URL: "",
        IMAGE_STORAGE_URL: '',
        FILES_STORAGE_URL: ''
    }
};

let config = dev;
// if (process?.env?.REACT_APP_STAGE && process?.env?.REACT_APP_STAGE.trim() === 'production') {
//     config = prod;
// }
// else if (process?.env?.REACT_APP_STAGE && process?.env?.REACT_APP_STAGE.trim() === 'int') {
//     config = int;
// }

let exportConfig = {
    APP_VERSION: 0.1,
    ...config

}
export default exportConfig;


const devBeteabon = {
    apiGateway: {
        API_SERVER_URL: "https://app.shareloc.co.il/",
        IMAGE_STORAGE_URL: '',
        FILES_STORAGE_URL: '',
        DISTRIBUTION_STORAGE_URL: '',
        CLIENT_DOMAIN_URL: "",
    }
};
let configBeteabon = devBeteabon;

export let exportConfigBeteabon = {
    APP_VERSION: 0.1,
    ...configBeteabon

}
