import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchRestaurantsFailed, fetchRestaurantsStart, fetchRestaurantsSuccess, setChosenRestaurant } from './restaurants-slice';
import restaurantsApi from '../../api/restaurants-api';

function* fetchRestaurants(): any {
    try {
        const { data, status, error } = yield call(restaurantsApi.getRestaurants);

        if (error) {
            yield put(fetchRestaurantsFailed(error));
            return;
        }

        yield put(fetchRestaurantsSuccess(data.accounts));

    } catch (error: any) {
        yield put(fetchRestaurantsFailed(error));
    }
}


function* setRestaurant(action: PayloadAction<any>): any {

    const chosenRestaurant = action.payload;
    localStorage.setItem('chosenRestaurant', JSON.stringify(chosenRestaurant));

}

function* restaurantsSaga() {
    yield takeLatest(fetchRestaurantsStart.type, fetchRestaurants);
    yield takeLatest(setChosenRestaurant.type, setRestaurant);

}

export default restaurantsSaga;