import React, { useEffect, useState } from 'react';
import { Picker, DatePicker } from 'antd-mobile';
import { PickerValue } from 'antd-mobile/es/components/picker-view';
import { TimeLimitRowBtn } from '../StyledComponents';

export const MessageDatePicker = (props: { name: string, onChange: Function, initVal: any }) => {

    const { name, onChange, initVal } = props;
    const [visible, setVisible] = useState(false);
    const [val, setVal] = useState<Date | null>(initVal || null);
    const [formattedDate, setFormattedDate] = useState<string>('בחר תאריך');

    const now = new Date();

    useEffect(() => {

        if (val) {
            let stringVal = val
            if (typeof (val) !== 'string') stringVal.toDateString()

            const date: Date = new Date(stringVal);
            const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', };
            const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('he-IL', options);
            const dateFormatted: string = formatter.format(date);
            setFormattedDate(dateFormatted);
        }
    }, [val]);

    return (
        <>
            <TimeLimitRowBtn
                onClick={() => { setVisible(true) }} >
                {formattedDate}
            </TimeLimitRowBtn >
            <DatePicker
                cancelText='ביטול'
                confirmText='אישור'
                visible={visible}
                onClose={() => { setVisible(false) }}
                onConfirm={(chosenVal) => {
                    setVal(chosenVal);
                    onChange(name, chosenVal);
                }}
                value={val ? (typeof val === 'string' ? new Date(val) : val) : now}
            >
            </DatePicker>
        </>

    )
}

export const MessageTimePicker = (props: { name: string, onChange: Function, initVal: any }) => {

    const { name, onChange, initVal } = props;
    const [visible, setVisible] = useState(false);
    const [val, setVal] = useState<PickerValue[] | null>(initVal || null);

    const timeColumns = buildTimeOptions();

    return (
        <>
            <TimeLimitRowBtn
                onClick={() => { setVisible(true) }}>
                {val ? val[1]?.toString().padStart(2, '0') + ":" + val[0]?.toString().padStart(2, '0') : 'בחר זמן'}
            </TimeLimitRowBtn >
            <Picker
                cancelText='ביטול'
                confirmText='אישור'
                columns={timeColumns}
                visible={visible}
                onClose={() => { setVisible(false) }}
                value={val ? val : [0, 0]}
                onConfirm={chosenVal => {
                    setVal(chosenVal);
                    onChange(name, chosenVal);
                }}
            />
        </>

    )
}

function buildTimeOptions() {
    const hours = []
    for (let i = 0; i < 24; i++) {
        hours.push({ label: i.toString().padStart(2, '0'), value: i })
    }
    const minutes = []
    for (let i = 0; i < 60; i++) {
        minutes.push({ label: i.toString().padStart(2, '0'), value: i })
    }

    return [minutes, hours]
}
