import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    createMessageFailed,
    createMessageStart,
    createMessageSuccess,
    deleteMessageFailed,
    deleteMessageStart,
    deleteMessageSuccess,
    getMessageFailed,
    getMessageStart,
    getMessageSuccess,
    updateMessageFailed,
    updateMessageStart,
    updateMessageSuccess
} from './edit-message-slice';
import { ICreateMessageBody, IDeleteMessageParams, IGetMessageParams, IUpdateMessageBody } from '../../../interfaces/api/messages';
import messageApi from '../../api/messages-api';


function* createMessage(action: PayloadAction<ICreateMessageBody>): any {
    try {
        const messageData = action.payload;
        const { data, status, error } = yield call(messageApi.createMessage, messageData);
        if (error) {
            yield put(createMessageFailed(error));
            return;
        }
        yield put(createMessageSuccess({})); 
    } catch (error: any) {
        yield put(createMessageFailed(error));
    }
}

function* updateMessage(action: PayloadAction<IUpdateMessageBody>): any {
    try {
        const params = action.payload;
        const { data, status, error } = yield call(messageApi.updateMessage, params);
        if (error) {
            yield put(updateMessageFailed(error));
            return;
        }
        yield put(updateMessageSuccess(data));
    } catch (error: any) {
        yield put(updateMessageFailed(error));
    }
}

function* deleteMessage(action: PayloadAction<IDeleteMessageParams>): any {
    try {
        const { messageId } = action.payload;
        const { data, status, error } = yield call(messageApi.deleteMessage, { messageId });
        if (error) {
            yield put(deleteMessageFailed(error));
        }
        yield put(deleteMessageSuccess(data));
    } catch (error: any) {
        yield put(deleteMessageFailed(error));
    }
}

function* getMessage(action: PayloadAction<IGetMessageParams>): any {
    try {
        const { messageId } = action.payload;
        const { data, status, error } = yield call(messageApi.getMessage, { messageId });
        if (error) {
            yield put(getMessageFailed(error));
            return;
        }
        yield put(getMessageSuccess(data));
    } catch (error: any) {
        yield put(getMessageFailed(error));
    }
}

function* editMessageSaga() {
    yield takeLatest(updateMessageStart.type, updateMessage);
    yield takeLatest(createMessageStart.type, createMessage);
    yield takeLatest(deleteMessageStart.type, deleteMessage);
    yield takeLatest(getMessageStart.type, getMessage);
}

export default editMessageSaga;