import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Image, Input, Typography, Switch, Spin } from 'antd';
import { Dialog, Toast } from 'antd-mobile';
import { useNavigate, useParams } from 'react-router-dom';
import Routes from '../../routes';
import { TimeLimit } from './message-components/TimeLimit';
import { DisplayLimit } from './message-components/DisplayLimit';
import { Parameters, parameterslist } from './message-components/Parameters';
import LeftArrow from '../../assets/icons/Left.svg';
import { PaddedContainer, MarginDivider, NoMarginDivider, StyledMainTitle } from '../common/StyledComponents';
import { FlexShadow, StyledFormItem } from './StyledComponents';
import { messageStatus } from '../../interfaces/models/message';
import { useAppSelector, useAppDispatch } from '../../store/config';
import { cleanEditMessageState, createMessageStart, deleteMessageStart, getMessageStart, updateMessageStart } from '../../store/features/edit-message/edit-message-slice';
import { getHours, getMinutes } from 'date-fns';
import { ICreateMessageBody, IUpdateMessageBody } from '../../interfaces/api/messages';
import ParametersTextarea from './message-components/ParametersTextarea';

const { Text } = Typography;
const { Item } = Form;

const Message = () => {

    const { id } = useParams();
    const { message: messageData, requestState, operation, error, loading } = useAppSelector(state => state.editMessage);
    const { chosenRestaurant } = useAppSelector(state => state.restaurants);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [form] = Form.useForm();

    const [chosenParameters, setChosenParameters] = useState<string[]>([]);
    const [textAreaVal, setTextAreaVal] = useState('');
    const [textareaOrParamsChange, setTextareaOrParamsChange] = useState<any>({ changed: '', value: 0 });
    const [status, setStatus] = useState<boolean>(false);

    useEffect(() => {
        dispatch(cleanEditMessageState());
        form.setFieldsValue({});
        if (id != 'add-new') {
            dispatch(getMessageStart({ messageId: id || '' }));
            setIsEdit(true);
        }
    }, []);

    useEffect(() => {
        if (requestState === 'finish' && (['delete', 'update', 'create'].includes(operation || ''))) {
            navigate(Routes.Dashboard.home)
        }
    }, [operation, requestState]);

    useEffect(() => {

        if (messageData) {
            form.setFieldsValue({
                status: messageData.status === 'active' ? true : false,
                title: messageData.title,
                content: messageData.content,
                useDates: messageData.useDates,
                startDate: messageData.startDate,
                startTime: messageData.startDate && [getMinutes(messageData.startDate), getHours(messageData.startDate)],
                endDate: messageData.endDate,
                endTime: messageData.endDate && [getMinutes(messageData.endDate), getHours(messageData.endDate)],
                restrictions: messageData.restrictions,
                brands: messageData.brands,
                areas: messageData.areas,
            })

            setTextAreaVal(messageData.content);
            setStatus(messageData.status === 'active' ? true : false);

            const existingParamaters = parameterslist.filter(
                option => messageData.content.includes('{{' + option.name + '}}'))
                .map(option => option.name);
            setChosenParameters(existingParamaters);

        } else {
            form.setFieldValue('startDate', new Date())
            form.setFieldValue('startTime', getNowTime())
        }
    }, [messageData]);


    useEffect(() => {
        if (error) {
            Toast.show({
                icon: 'fail',
                content: 'אירעה שגיאה',
                duration: 2500
            });
        }
    }, [error]);

    useEffect(() => {
        setTextareaOrParamsChange({
            changed: 'textareaChange',
            value: textareaOrParamsChange.value++
        })
    }, [textAreaVal]);

    useEffect(() => {
        setTextareaOrParamsChange({
            changed: 'parametersChange',
            value: textareaOrParamsChange.value++
        })
    }, [chosenParameters]);


    useEffect(() => {
        if (textareaOrParamsChange.changed === 'textareaChange') {
            handleTextareaChange();
        }

        if (textareaOrParamsChange.changed === 'parametersChange') {
            handleParametersChange();
        }

    }, [textareaOrParamsChange]);

    const handleTextareaChange = () => {
        //if parameter was deleted from text 
        chosenParameters.forEach((chosenParameter: string) => {
            if (!textAreaVal.includes('{{' + chosenParameter + '}}')) {
                const newParameters = chosenParameters.filter(parameter => parameter !== chosenParameter);
                setChosenParameters(newParameters);
            }
        })
    }

    const handleParametersChange = () => {
        //adds new parameter to text 
        chosenParameters.forEach((chosenParameter: string) => {
            if (!textAreaVal.includes('{{' + chosenParameter + '}}')) {
                setTextAreaVal(textAreaVal + ' {{' + chosenParameter + '}}')
            }
        })

        //delete removed parameters from text
        const notChosenParameters = parameterslist.filter(option => !chosenParameters.includes(option.name)).map(option => option.name);
        notChosenParameters.forEach((notChosenParameter: any) => {
            if (textAreaVal.includes('{{' + notChosenParameter + '}}')) {
                let newText = textAreaVal.replace('{{' + notChosenParameter + '}}', '');
                setTextAreaVal(newText);
            }
        })

    }

    async function onFinish(data: any): Promise<void> {

        try {
            if (chosenRestaurant) {
                const messageBody = getMessageBody(data);
                isEdit ?
                    dispatch(updateMessageStart({ messageId: id || '', data: messageBody } as IUpdateMessageBody)) :
                    dispatch(createMessageStart(messageBody));
            }

        } catch (error) {
            console.error(error);
        }
    };

    const getMessageBody = (data: any) => {

        let messageBody: ICreateMessageBody = {
            status: data.status ? 'active' : 'draft' as messageStatus,
            title: data.title,
            content: textAreaVal,
            restaurants: chosenRestaurant?.account.id || '',
            useDates: data.useDates || false,
            restrictions: data.restrictions || false,
        }

        if (data.useDates) {

            const startDateFormated = getFormatedDateTime(data.startDate, data.startTime)
            const endDateFormated = getFormatedDateTime(data.endDate, data.endTime)

            if (startDateFormated) messageBody['startDate'] = startDateFormated.toISOString();
            if (endDateFormated) messageBody['endDate'] = endDateFormated.toISOString();

        }
        if (data.restrictions) {
            if (data.brands?.length > 0) messageBody['brands'] = data.brands;
            if (data.areas?.length > 0) messageBody['areas'] = data.areas;
        }

        return messageBody;
    }

    const onChange = (field: string, value: any) => {
        form.setFieldValue([field], value)
    };


    return (
        <Flex
            vertical
            justify='space-between'
            style={{ width: '100%', minHeight: '100vh' }}>
            <Form
                form={form}
                name="message_form"
                layout="vertical"
                requiredMark={false}
                onFinish={onFinish}
                style={{ opacity: loading ? '0.5' : '1' }}
            >

                <PaddedContainer
                    justify="space-between"
                    align='center'
                    style={{ paddingTop: '17.5px', paddingBottom: '0' }} >
                    <Text onClick={() => navigate(Routes.Messages.Messages)} >
                        <Image preview={false} src={LeftArrow} />
                        חזרה
                    </Text>
                    <StyledMainTitle level={4}>
                        {isEdit ? 'עריכת מסר' : ' יצירת מסר חדש'}
                    </StyledMainTitle>
                    <StyledFormItem>
                        <Button
                            htmlType="submit"
                            shape="round"
                            type="link"
                            style={{ fontWeight: 500, padding: 0 }}>
                            שמירה
                        </Button>
                    </StyledFormItem>
                </PaddedContainer>

                <NoMarginDivider />

                <PaddedContainer vertical>

                    <Item
                        name="status"
                        valuePropName="checked"
                        style={{ marginBottom: '10px' }} >
                        <Flex justify='space-between'>
                            <Text>סטטוס</Text>
                            <Switch
                                onChange={(checked) => {
                                    setStatus(checked);
                                    form.setFieldValue('status', checked);
                                }}
                                checked={status}
                            />
                        </Flex>
                    </Item>

                    <FlexShadow vertical>
                        <Item
                            name="title"
                            style={{ width: '100%', margin: 0, borderBottom: '1px solid #EEE' }}
                            rules={[{ required: true, message: 'שדה חובה' }]}
                        >
                            <Input
                                value={form.getFieldValue('title')}
                                placeholder='טקסט'
                                bordered={false}
                                prefix={<Text style={{ paddingLeft: "10px" }}>כותרת</Text>} />
                        </Item>
                        <ParametersTextarea
                            textAreaVal={textAreaVal}
                            setTextAreaVal={setTextAreaVal}
                        />
                    </FlexShadow>

                    <Flex vertical>
                        <Parameters chosenParameters={chosenParameters} setChosenParameters={setChosenParameters} />
                    </Flex>
                    {
                        !isEdit || (form.getFieldValue('restrictions') !== undefined && form.getFieldValue('useDates') !== undefined) ?
                            <>
                                <StyledFormItem
                                    name={'useDates'} >
                                    <Flex vertical style={{ marginBottom: '20px', marginTop: '20px' }}  >
                                        <TimeLimit onChange={onChange} form={form} />
                                        <MarginDivider />
                                    </Flex>
                                </StyledFormItem>

                                <StyledFormItem
                                    name={'restrictions'} >
                                    <Flex vertical style={{ marginBottom: '20px' }}  >
                                        <DisplayLimit onChange={onChange} form={form} />
                                        <MarginDivider />
                                    </Flex>
                                </StyledFormItem>
                            </>
                            : <Spin style={{ margin: '30px' }} />
                    }
                </PaddedContainer>
            </Form>
            {
                isEdit &&
                <PaddedContainer style={{ margin: '0 8px 30px' }}>
                    <Button type="primary" danger block
                        onClick={
                            async () => {
                                await Dialog.confirm({
                                    content: <div style={{ textAlign: 'center' }}>
                                        האם אתה בטוח שברצונך למחוק את המסר <b>{messageData?.title}</b>?
                                    </div>,
                                    onConfirm: () => {
                                        dispatch(deleteMessageStart({ messageId: id || '' }));
                                    },
                                    confirmText: "מחיקה",
                                    cancelText: "ביטול",
                                })
                            }}
                    >
                        מחיקת מסר
                    </Button>
                </PaddedContainer>
            }
        </Flex>
    )
}

export default Message;

function getFormatedDateTime(date: Date | string, time: [number, number]) {

    if (!date) return null;
    const dateObj = typeof date === 'string' ? new Date(date) : date;

    if (!time) time = [0, 0];

    return new Date(
        dateObj.getFullYear(),
        dateObj.getMonth(),
        dateObj.getDate(),
        time[1],
        time[0]
    );

}

export const getNowTime = () => {
    let now = new Date();
    let hour = now.getHours();
    let minute = now.getMinutes();
    return [minute, hour]
}