import React, { useEffect } from 'react'
import { authLogoutStart } from '../../store/features/auth-user/auth-user-slice';
import { useAppDispatch } from '../../store/config';

const Logout = () => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(authLogoutStart());
	}, []);
	return null;
}

export default React.memo(Logout);