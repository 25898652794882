import createSagaMiddleware from 'redux-saga';
import { configureStore, Action } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './rootReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import messagesSaga from './features/messages/messages-saga';
import authUserSaga from './features/auth-user/auth-user-saga';
import editMessageSaga from './features/edit-message/edit-message-saga';
import restaurantsSaga from './features/restarurants/restaurants-saga';


const saga = createSagaMiddleware({});

const middlewares: any[] = [];
middlewares.push(saga);

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

saga.run(authUserSaga);
saga.run(messagesSaga);
saga.run(editMessageSaga);
saga.run(restaurantsSaga);

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store