import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IMessagesState } from '../../../interfaces/store/messages-interfaces';
import { IGetMessagesParams } from '../../../interfaces/api/messages';

const initialState: IMessagesState = {
    messages: [],
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanMessagesState = (state: IMessagesState) => {
    return initialState;
};

const _fetchMessagesStart = (state: IMessagesState, { payload }: PayloadAction<{ params: IGetMessagesParams }>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'read';
}

const _fetchMessagesSuccess = (state: IMessagesState, { payload }: PayloadAction<any>) => {
    state.messages = payload.data;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _fetchMessagesFailed = (state: IMessagesState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const MessagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        cleanMessagesState: _cleanMessagesState,
        fetchMessagesStart: _fetchMessagesStart,
        fetchMessagesSuccess: _fetchMessagesSuccess,
        fetchMessagesFailed: _fetchMessagesFailed
    }
})

const { actions, reducer } = MessagesSlice;
export const {
    cleanMessagesState,
    fetchMessagesStart,
    fetchMessagesSuccess,
    fetchMessagesFailed
} = actions;

export default reducer;
