import { beteabonInstance } from '../../config/axios';
import ServerRoutes from './routes';
import { getParamsUrl, handleAxiosError, replaceUrlId } from './utils/common';
import {
    IGetMessagesParams,
    IGetMessageParams,
    ICreateMessageBody,
    IUpdateMessageBody,
    IDeleteMessageParams
} from '../../interfaces/api/messages';

const getMessages = async (params: IGetMessagesParams) => {
    try {
        let url = getParamsUrl(ServerRoutes.messages.getMessages, params);
        const { status, data } = await beteabonInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getMessage = async (params: IGetMessageParams) => {
    try {
        let url = replaceUrlId(ServerRoutes.messages.getMessage, params.messageId);
        const { status, data } = await beteabonInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const createMessage = async (messageInfo: ICreateMessageBody) => {
    try {
        const { status, data } = await beteabonInstance.post(ServerRoutes.messages.createMessage, messageInfo);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const updateMessage = async (messageInfo: IUpdateMessageBody) => {
    try {
        let url = replaceUrlId(ServerRoutes.messages.updateMessage, messageInfo.messageId);
        const { status, data } = await beteabonInstance.put(url, messageInfo.data);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const deleteMessage = async (params: IDeleteMessageParams) => {
    try {
        let url = replaceUrlId(ServerRoutes.messages.deleteMessage, params.messageId);
        const { status, data } = await beteabonInstance.delete(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 404:
                message = 'Message not found.';
                break;
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getBrands = async (guid: string) => {
    try {
        let url = ServerRoutes.messages.getBrands.replace('<account guid>', guid);
        const { status, data } = await beteabonInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const getAreas = async (guid: string) => {
    try {
        let url = ServerRoutes.messages.getAreas.replace('<account guid>', guid);
        const { status, data } = await beteabonInstance.get(url);
        return { status, data };
    }
    catch (error: any) {
        const err = handleAxiosError(error);
        let message = 'Some error happen, please try again. If the error returns please contact support';
        switch (err.status) {
            case 500:
                message = 'Server error. Please contact support';
                break;
            default:
                break;
        }
        return {
            error: {
                message: message,
                status: err.status
            }
        };
    }
}

const messagesApi = {
    getMessages,
    getMessage,
    createMessage,
    updateMessage,
    deleteMessage,
    getBrands,
    getAreas,
}

export default messagesApi;