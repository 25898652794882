import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../interfaces/base';
import { IEditMessageState } from '../../../interfaces/store/messages-interfaces';
import { ICreateMessageBody, IDeleteMessageParams, IGetMessageParams, IUpdateMessageBody } from '../../../interfaces/api/messages';

const initialState: IEditMessageState = {
    message: null,
    loading: false,
    error: null,
    requestState: 'prepare',
    operation: null
}

const _cleanEditMessageState = (state: IEditMessageState) => {
    return initialState;
};

const _getMessageStart = (state: IEditMessageState, { payload }: PayloadAction<IGetMessageParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start'; 
    state.operation = 'read';
}

const _getMessageSuccess = (state: IEditMessageState, { payload }: PayloadAction<any>) => {
    state.message = payload.data;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _getMessageFailed = (state: IEditMessageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _updateMessageStart = (state: IEditMessageState, { payload }: PayloadAction<IUpdateMessageBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'update';
}

const _updateMessageSuccess = (state: IEditMessageState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _updateMessageFailed = (state: IEditMessageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _createMessageStart = (state: IEditMessageState, { payload }: PayloadAction<ICreateMessageBody>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'create';
}

const _createMessageSuccess = (state: IEditMessageState, { payload }: PayloadAction<any>) => {
    // state.message = payload;
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _createMessageFailed = (state: IEditMessageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

const _deleteMessageStart = (state: IEditMessageState, { payload }: PayloadAction<IDeleteMessageParams>) => {
    state.loading = true;
    state.error = null;
    state.requestState = 'start';
    state.operation = 'delete';
}

const _deleteMessageSuccess = (state: IEditMessageState, { payload }: PayloadAction<any>) => {
    state.loading = false;
    state.error = null;
    state.requestState = 'finish';
}

const _deleteMessageFailed = (state: IEditMessageState, { payload }: PayloadAction<IError>) => {
    state.loading = false;
    state.error = payload;
    state.requestState = 'error';
}

// const _publishMessageStart = (state: IEditMessageState, { payload }: PayloadAction<{params: { messageId: string, publish: boolean }}>) => {
//     state.loading = true;
//     state.error = null;
//     state.requestState = 'start';
// }

// const _publishMessageSuccess = (state: IEditMessageState, { payload }: PayloadAction<any>) => {
//     state.loading = false;
//     state.error = null;
//     state.requestState = 'finish';
// }

// const _publishMessageFailed = (state: IEditMessageState, { payload }: PayloadAction<IError>) => {
//     state.loading = false;
//     state.error = payload;
//     state.requestState = 'error';
// }


const EditMessageSlice = createSlice({
    name: 'editMessage',
    initialState,
    reducers: {
        cleanEditMessageState: _cleanEditMessageState,
        getMessageStart: _getMessageStart,
        getMessageSuccess: _getMessageSuccess,
        getMessageFailed: _getMessageFailed,
        updateMessageStart: _updateMessageStart,
        updateMessageSuccess: _updateMessageSuccess,
        updateMessageFailed: _updateMessageFailed,
        createMessageStart: _createMessageStart,
        createMessageSuccess: _createMessageSuccess,
        createMessageFailed: _createMessageFailed,
        deleteMessageStart: _deleteMessageStart,
        deleteMessageSuccess: _deleteMessageSuccess,
        deleteMessageFailed: _deleteMessageFailed,
        // publishMessageStart: _publishMessageStart,
        // publishMessageSuccess: _publishMessageSuccess,
        // publishMessageFailed: _publishMessageFailed,
    }
})

const { actions, reducer } = EditMessageSlice;
export const {
    cleanEditMessageState,
    getMessageStart,
    getMessageSuccess,
    getMessageFailed,
    updateMessageStart,
    updateMessageSuccess,
    updateMessageFailed,
    createMessageStart,
    createMessageSuccess,
    createMessageFailed,
    deleteMessageStart,
    deleteMessageSuccess,
    deleteMessageFailed,
    // publishMessageStart,
    // publishMessageSuccess,
    // publishMessageFailed,

} = actions;

export default reducer;
