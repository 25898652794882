export const AuthRoutes = {
    login: '/login',
    logout: '/logout'
}

export const DashboardRoutes = {
    home: '/home',
}

export const MessagesRoutes = {
    MessagePage: '/messages/:id',
    Messages: '/messages',
}

const Routes = {
    Auth: AuthRoutes,
    Dashboard: DashboardRoutes,
    Messages: MessagesRoutes,
}

export default Routes;