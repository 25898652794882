import React from 'react'
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom';
import Routes from '../../routes';
import LoginForm from './LoginForm';

const LoginNavigation = () => {
    return (
        <Router>
            <Switch>
                <Route path={Routes.Auth.login} element={<LoginForm />}></Route>
                <Route
                    path="*"
                    element={<Navigate to={Routes.Auth.login} replace />}
                />
            </Switch>
        </Router>
    )
}

export default LoginNavigation;
