import React, { useEffect, useState } from 'react';
import { Button, Flex, Image, Typography } from 'antd';
import { CheckList, Popup } from 'antd-mobile';
import Restaurant from '../../assets/icons/room-service.svg';
import { PaddedContainer, StyledMainTitle } from '../common/StyledComponents';
import { useAppDispatch, useAppSelector } from '../../store/config';
import { fetchRestaurantsStart, setChosenRestaurant } from '../../store/features/restarurants/restaurants-slice';
import { IRestaurant } from '../../interfaces/models/user';
import { useNavigate } from 'react-router-dom';
import Routes from '../../routes';


const { Text } = Typography;


const ChooseRestaurant = () => {

    const [visible, setVisible] = useState<boolean>(false);
    const { restaurants, chosenRestaurant } = useAppSelector(state => state.restaurants);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!chosenRestaurant) dispatch(fetchRestaurantsStart());
    }, []);

    useEffect(() => {
        if (!chosenRestaurant) {
            if (restaurants.length == 1) {
                dispatch(setChosenRestaurant(restaurants[0]));
            } else {
                setVisible(true);
            }
        }
    }, [restaurants]);

    useEffect(() => {
        if (chosenRestaurant) {
            setVisible(false);
        }
    }, [chosenRestaurant]);

    return (
        <Flex vertical style={{ width: '100%' }}>
            <Popup
                visible={visible}
                maskStyle={{ background: '#243E6333' }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    height: '50vh',
                }} >
                <Flex vertical style={{ width: '100%' }}>

                    <PaddedContainer
                        justify="center"
                        align='center'
                        style={{ paddingBottom: '0px', width: '100%' }} >
                        <StyledMainTitle level={4}>בחירת מסעדה</StyledMainTitle>
                    </PaddedContainer>

                    <CheckList
                        style={{
                            maxHeight: '50vh',
                            overflowY: 'scroll',
                            padding: '30px 33px 10px'
                        }}
                        className='choose-restaurant-list'
                    >

                        {restaurants.length > 0 ? restaurants.map((restaurant: IRestaurant) => (
                            <CheckList.Item key={restaurant.account.id} value={restaurant.account.name}
                                onClick={() => dispatch(setChosenRestaurant(restaurant))}>
                                <Flex gap={10} style={{ width: '100%', marginTop: '10px' }}>
                                    <Image
                                        preview={false}
                                        src={Restaurant} />
                                    <Text style={{ fontSize: '18px' }}> {restaurant.account.name}</Text>
                                </Flex>
                            </CheckList.Item>
                        )) :
                            <Flex gap={20} vertical justify='center' align='center' style={{ height: '100%' }}>
                                <Text>לא נמצאו מסעדות</Text>
                                <Flex>
                                    <Button
                                        type="primary"
                                        style={{ marginBottom: '50px' }}
                                        onClick={() => { navigate(Routes.Auth.logout) }}
                                    >
                                        התנתקות
                                    </Button>
                                </Flex>
                            </Flex>
                        }
                    </CheckList>
                </Flex>
            </Popup>
        </Flex>
    )
}

export default ChooseRestaurant;