import React, { createRef, useRef } from 'react';
import { Flex } from 'antd';
import { PinCodeInput } from './StyledComponents';
import { isNumber } from '../../utils/utils';

interface IPinInput {
    value: string[];
    setValue: Function;
    disabled?: boolean;
    numOfDigits: number;
}


const PinInput = (props: IPinInput) => {

    const {
        value,
        setValue,
        disabled,
        numOfDigits
    } = props;

    const refs: any = useRef(value.map(() => createRef()));

    return (
        <Flex
            gap={16}
            justify='center'
            style={{ flexDirection: 'row-reverse' }}>

            {
                Array.from(Array(numOfDigits)).map((x, index) => (
                    <PinCodeInput
                        ref={refs.current[index]}
                        key={index}
                        value={value[index]}
                        onChange={() => { }}
                        onKeyDown={(event) => {
                            if (event.key === 'Backspace') {
                                let update = [...value];
                                update[index] = '';
                                setValue(update);
                                if (index > 0) {
                                    refs.current[index - 1].current.focus()
                                }
                                return;
                            }
                            if (isNumber(event.key)) {
                                let update = [...value];
                                update[index] = event.key;
                                setValue(update);
                                if (index + 1 < numOfDigits) {
                                    refs.current[index + 1].current.focus()
                                }
                            }
                        }}
                        disabled={disabled}
                    />
                ))
            }
        </Flex>
    )
}

export default PinInput;